.listing-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.listing-filter .dropdown-cover {
  width: 160px;
  max-width: 100%;
  margin: 0px 10px;
}
.listing-filter .date-range-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 330px;
  max-width: 100%;
  background-color: "var(--light-green)";
}
.listing-filter .date-range-cover .date-picker-cover:not(:first-child) {
  margin-left: 10px;
}
.listing-filter .date-picker-cover.single-date-cover {
  width: 160px;
}
.listing-filter .left-side-filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 44%;
}
.listing-filter .left-side-filters .left-filter-child:not(:last-child) {
  margin-right: 10px;
}
.listing-filter .right-side-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 56%;
}
.listing-filter .right-side-filters button:not(:first-child) {
  margin-left: 10px;
}

@media screen and (max-width: 1023px) {
  .listing-filter {
    flex-direction: column;
  }
  .listing-filter .dropdown-cover {
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
  }
  .listing-filter .date-range-cover {
    width: 100%;
  }
  .listing-filter .left-side-filters {
    width: 100% !important;
  }
  .listing-filter .right-side-filters {
    width: 100% !important;
    justify-content: space-between;
  }
  .listing-filter .right-side-filters button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
