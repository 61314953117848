.page-contact-info ul{
    margin: 0px;
}
.page-contact-info ul li{
    padding: 20px 0px;
}
.page-contact-info h2 {
    color: #7bc1ba;
}
.page-contact-info p span{
    font-weight: bold;
}

.page-contact-info ul.col-lg-4 {
    width: 33%;
    display: inline-block;
    vertical-align: top;
    padding: 10px 20px;
}
@media (max-width: 1024px) {
    page-contact-info ul.col-lg-4 {
        width:50%;
    }
}
@media (max-width: 767px) {
    page-contact-info ul.col-lg-4 {
        width:100%;
    }
}