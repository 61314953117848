@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;900&display=swap");

* {
  outline: none;
  box-sizing: border-box;
  word-break: break-word;
}

ul,
ol {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  line-height: 1.4;
  font-weight: 500;
}
p {
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --white: #fff;
  --black: #000;
  --light-green: #53c392;
  --berwick-berry: #7183a9;
  --light-gray: #4a4a4a;
  --bright-red: #ff4d2e;
  --bright-light-red: #ee4040;
  --bright-red-mini: #fca28f;
  --bright-purple: #0303fc;
  --bright-purple-mini: #85b4ff;
  --persian-green: #009991;
  --persian-green-mini: #00adad;
  --greenish-brown: #fcaa05;
  --greenish-brown-mini: #ffd991;
  /* box shadow */
  --app-bar-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.03);
}

a {
  text-decoration: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.align-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.justify-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}
.align-flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.nowrap{
  white-space: nowrap;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.underline {
  text-decoration: underline !important;
}
.bold {
  font-weight: 800 !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.regular {
  font-size: 500 !important;
}
.thin {
  font-weight: 400 !important;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.left {
  text-align: left !important;
}

.pointer {
  cursor: pointer !important;
}
.p-0 {
  padding: 0px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-10border {
  margin-bottom: 10px !important;
  /* background-color: #ee4040; */
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #ee4040;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 80px;
}
.dotProfileHeader {
  height: 12px;
  width: 12px;
  background-color: #ee4040;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
}
.dotProfile {
  height: 12px;
  width: 12px;
  background-color: #ee4040;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
  padding-top: 4px;
  /* position: absolute; */
  left: 5px;
  /* top: -px; */
}
.dot2 {
  height: 25px;
  width: 25px;
  background-color: #ee4040;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 120px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-30 {
  margin-right: 30px;
}

.color-blue {
  color: #152567;
}
.color-text {
  color: #707070 !important;
}
.color-white {
  color: white !important;
}
.bg-gray {
  background-color: #fafafa;
  padding: 20px;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.bg-white {
  padding: 20px;
  background-color: white;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.page-claim-detail h4 {
  font-weight: 500;
}
.page-claim-detail p {
  font-size: 14px;
}

.react-file-reader-button {
  display: inline;
}

.MuiFormControl-root {
  width: 100%;
}

.benefit-chips-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -5px;
  max-height: 300px;
  overflow: auto;
}
.benefit-chip {
  margin: 5px;
  height: auto;
  background-color: #53c392 !important;
  color: white;
}
.benefit-chip .MuiChip-label {
  padding: 5px 12px;
  white-space: initial;
  color: white;
}

.page-signup .MuiAvatar-square,
.page-personal-info .MuiAvatar-square {
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.page-signup .MuiAvatar-square img,
.page-personal-info .MuiAvatar-square img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
/* .MuiAvatar-root{

} */

.day-schedule .MuiTypography-root {
  font-weight: 500;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-input.MuiOutlinedInput.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
.notranslate {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
/* .MuiInputBase-input.MuiInputAdornment-positionStart {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
.MuiInputBase-adornedStart {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
} */
.days-list .MuiGrid-root:last-child div {
  border-bottom: none;
}

.page-verification-modal .close-icon-cover {
  position: absolute;
  top: 15px;
  right: 15px;
}
.page-verification-modal .page-patient-verification .bg-white {
  margin-bottom: 0px !important;
}

.MuiChip-root{
  height: auto !important;
  padding: 5px 0px !important;
}

.MuiChip-root .MuiChip-label{
  white-space: initial;
}


/* Using codepen button style :) */
.button {
  display: inline-block;
  border: 0;
  outline: 0;
  padding: 12px 16px;
  line-height: 1.4;
  border-radius: 5px;
  border: 1px solid black;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Tahoma, Sans-Serif;
  color: black !important;
  font-size: 1.2em;
  cursor: pointer;
  /* Important part */
  position: relative;
  transition: padding-right .3s ease-out;
}
.button.loading {
  background-color: #CCC;
  padding-right: 40px;
}
.button.loading:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  right: 6px;
  top: 50%;
  width: 0px;
  height: 0px;
  margin-top: -2px;
  border: 2px solid rgba(255,255,255,0.5);
  border-left-color: #FFF;
  border-top-color: #FFF;
  animation: spin .6s infinite linear, grow .3s forwards ease-out;
}
@keyframes spin { 
  to {
      transform: rotate(359deg);
  }
}
@keyframes grow { 
  to {
      width: 14px;
      height: 14px;
      margin-top: -8px;
      right: 13px;
  }

  
}

@-webkit-keyframes glowing {
  0% {
      background-color: #b20000;
      -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: #ff0000;
      -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
      background-color: #b20000;
      -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
      background-color: #b20000;
      -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: #ff0000;
      -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
      background-color: #b20000;
      -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
      background-color: yellow;
      box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: #ff0000;
      box-shadow: 0 0 90px #ff0000;
  }
  100% {
      background-color: #b20000;
      box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
      background-color: #b20000;
      box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: #ff0000;
      box-shadow: 0 0 40px #ff0000;
  }
  100% {
      background-color: #b20000;
      box-shadow: 0 0 3px #b20000;
  }
}


@-webkit-keyframes glowingEff {
  0% {
      background-color: green;
      -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: green;
      -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
      background-color: green;
      -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowingEff {
  0% {
      background-color: green;
      -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: green;
      -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
      background-color: green;
      -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowingEff {
  0% {
      background-color: green;
      box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: green;
      box-shadow: 0 0 90px #ff0000;
  }
  100% {
      background-color: green;
      box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowingEff {
  0% {
      background-color: green;
      box-shadow: 0 0 3px #b20000;
  }
  50% {
      background-color: green;
      box-shadow: 0 0 40px #ff0000;
  }
  100% {
      background-color: green;
      box-shadow: 0 0 3px #b20000;
  }
}


@-webkit-keyframes glowingWB {
  0% {
      
      -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
      
      -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
      
      -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowingWB {
  0% {
      
      -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
      
      -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
      
      -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowingWB {
  0% {
      
      box-shadow: 0 0 3px #b20000;
  }
  50% {
      
      box-shadow: 0 0 90px #ff0000;
  }
  100% {
      
      box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowingWB {
  0% {
      
      box-shadow: 0 0 3px #b20000;
  }
  50% {
      
      box-shadow: 0 0 40px #ff0000;
  }
  100% {
      
      box-shadow: 0 0 3px #b20000;
  }
}

/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
} */

.container {
  max-width: 90%;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
}

.subscription-info {
  margin-top: 0px;
}

.subscription-item {
  width: 50%;
  display: inline-block;
  padding: 5px;
  font-size: 18px;
}

.label {
  font-weight: bold;
}

.value {
  margin-left: 10px;
}

@media screen and (max-width: 992px) {
  .subscription-item {
      width: 100%;
  }
}

.subscription-card{
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.15);
  padding: 1vw 1.5vw;
  border-radius: 4px;
  height: 100%;
}
.subscription-card button{
  padding: 10px !important;
}
.subscription-card .planStatus{
  font-size: 0.8vw;
  padding: 0.3vw 0.7vw;
  color: white;
  letter-spacing: 1px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  margin-right: 10px;
}
.subscription-card .planName{
  font-size: 1.6vw;
}
.subscription-card .planPrice{
  font-size: 2vw;
  margin-top: 1vw;
}
.subscription-card .planPrice span{
  font-weight: 400;
  font-size: 1.5vw;
}
.subscription-card .startDate p{
  font-size: 1.2vw;
  margin: 0;
}



.subscription-card.active{
  border: 2px solid #21ba45;
  background: #ecf8ec;
}
.subscription-card.active .planStatus{
  background: #21ba45;
}



.subscription-card.renewel{
  border: 2px solid #e99e14;
  background: #fff9f0;
}
.subscription-card.renewel .planStatus{
  background: #e99e14;
}

.see-more-plans-cover{
  /* margin: 1vw 0 2.5vw; */
  border-top: 1px solid #6a6a6a;
  position: relative;
}
.see-more-plans-cover h3{
  position: absolute;
  top: 50%;
  font-size: 1.4vw;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  background: white;
  
}

.subscription-card.morePlans{
  border: 2px solid #2285d0;
  background: #edf4fb;
  padding: 0.8vw 1.2vw;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.subscription-card.morePlans .subs-upper-info{
  flex: 1;
}
.subscription-card .planOffer{
  font-size: 0.9vw;
  padding: 0.25vw 0.8vw;
  color: white;
  display: inline-block;
  background: #028020;
  border-radius: 30px;
  display: inline-block;
  font-weight: 400;
  margin-top: 0.5vw;
  margin-bottom: 0;
}
.subscription-card.morePlans .icon{
  color: #2285d0
}
.subscription-card.morePlans .planName{
  font-size: 1.2vw;
}
.subscription-card.morePlans .planPrice{
  font-size: 1.5vw;
  margin-top: 0.6vw;
}
.subscription-card.morePlans .planPrice span{
  font-size: 1.1vw;
}
.subscription-card.morePlans .startDate p{
  font-size: 1vw;
  margin-bottom: 10px;
}
.subscription-card.morePlans .startDate p:last-child{
  white-space: nowrap;
}
.subscription-card.morePlans .insurance-companies p{
  font-size: 1vw;
  margin-bottom: 10px;
}


@media screen and (max-width: 1100px) {
  .subscription-card .icon{
      font-size: 16px !important;
  }
}
@media screen and (max-width: 992px) {
  .subscription-card .icon{
      font-size: 12px !important;
  }

  .subscription-card{
      padding: 9px 14px;
      border-radius: 4px;
      height: 100%;
  }
  .subscription-card .planStatus{
      font-size: 8px;
      padding: 3px 7px;
  }
  .subscription-card .planName{
      font-size: 15px;
  }
  .subscription-card .planPrice{
      font-size: 20px;
      margin-top: 10px;
  }
  .subscription-card .planPrice span{
      font-size: 15px;
  }
  .subscription-card .startDate p{
      font-size: 10px;
  }
  
  .see-more-plans-cover{
      /* margin: 10px 0 25px; */
      border-top: 1px solid #6a6a6a;
      position: relative;
  }
  .see-more-plans-cover h3{
      font-size: 14px;   
  }
  
  .subscription-card.morePlans{
      border: 2px solid #2285d0;
      padding: 8px 12px;
  }
  .subscription-card.morePlans .planName{
      font-size: 12px;
  }
  .subscription-card.morePlans .planPrice{
      font-size: 15px;
      margin-top: 6px
  }
  .subscription-card.morePlans .planPrice span{
      font-size: 11px;
  }
  .subscription-card.morePlans .startDate p{
      font-size: 10px;
      margin-bottom: 10px;
  }
  .subscription-card.morePlans .insurance-companies p{
      font-size: 10px;
      margin-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .see-more-plans-cover h3{
      padding: 0 8px;
      font-size: 12px;
  }
}
@media screen and (max-width: 420px) {
  .all-pricingPlans .col-3{
      width: 100% !important;
  }
}

.planOfferOnModal{
  font-size: 14px;
  padding: 6px 14px;
  color: white;
  background: #028020;
  border-radius: 30px;
  display: inline-block;
  width: max-content;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;   
}

.country-code-cover {
  position: relative;
}
.country-code-cover .country-code-value {
  position: absolute;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  display: none;
  top: 33px;
  left: 20px;
  margin: 0px;
  z-index: 9;
  
}
.country-code-cover.dynamic-cc .country-code-value {
  display: block;
}
.country-code-cover.dynamic-cc .input-value input {
  padding-left: 50px;
}


.subscription-card .planStatus.active{
  background: green;
}
.subscription-card .planStatus.inactive{
  background: red;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: black;
  font-weight: 600;
  background: #dee0ee;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #575b71;
  color: #fff;
} 
.quantity__minus {
  border-radius: 3px 0 0 3px;
  font-size: 22px;
}
.quantity__plus {
  border-radius: 0 3px 3px 0;
}
.quantity__input {
  width: 50px;
  height: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dee0ee;
  border-bottom: 2px solid #dee0ee;
  border-left: 1px solid #dee0ee;
  border-right: 2px solid #dee0ee;
  background: #fff;
  color: black;
}
.cart-icon svg{
  fill: white;
}
.cart-icon .MuiBadge-badge{
  top: 0;
}