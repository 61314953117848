.menu-options-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: -24px;
}
.menu-options-list .single-menu {
    position: relative;
}
.menu-options-list .single-menu:not(:last-child)::after {
    content: "";
    position: absolute;
    height: 20px;
    transform: translateY(-50%);
    right: 0px;
    width: 1px;
    top: 50%;
    background-color: #d9d9d9;
}
.menu-options-list .single-menu.active::after {
    display: none;
}
.menu-options-list .single-menu a {
    color: white;
    padding: 13px 8px;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    letter-spacing: 0.5px;
    min-width: 120px;
    white-space: nowrap;
    text-align: center;
    display: block;
    transition: 0.2s;
}
.menu-options-list .single-menu.active a {
    color: #53c392;
    background: white;
    border-radius: 8px 8px 0px 0px;
}

.menu-options-list .single-menu.active a::before {
    content: "";
    background-image: url("../../../public/images/header-curve-left.svg");
    position: absolute;
    bottom: 0px;
    left: -7px;
    height: 9px;
    background-repeat: no-repeat;
    width: 8px;
}
.menu-options-list .single-menu.active a::after {
    content: "";
    background-image: url("../../../public/images/header-curve-right.svg");
    position: absolute;
    bottom: 0px;
    right: -5px;
    height: 9px;
    background-repeat: no-repeat;
    width: 8px;
}

.menu-options-list .single-menu.active {
    margin-left: -1px;
}

.header-cover {
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 125px;
    z-index: 99;
}
.header-cover.has-subscription-strip {
    height: 165px;
}

@media screen and (max-width: 1023px) {
    .menu-options-list .single-menu a {
        color: white;
        padding: 13px 15px 13px;
        font-size: 13px;
        min-width: 90px;
    }
}
@media screen and (max-width: 900px) {
    .header-cover {
        height: 109px;
    }
    .mainWrapper {
        height: calc(100vh - 109px) !important;
    }
}

@media screen and (max-width: 767px) {
    .header-cover .right-portion {
        padding-top: 0px;
    }
}

@media screen and (max-width: 600px) {
    .mainWrapper {
        padding: 15px 20px !important;
    }
}
@media screen and (max-width: 576px) {
    .header-cover {
        height: 105px;
    }
    .mainWrapper {
        height: calc(100vh - 105px) !important;
    }
}
