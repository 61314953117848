.single-payment-method {
  width: 100%;
  margin: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.single-payment-method .radio-label {
  width: 100%;
  display: block;
  position: relative;
  padding: 5px;
  text-align: center;
  height: 60px;
  font-size: 16px;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}
.single-payment-method .radio-label img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}
.single-payment-method .radio-label .icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 20px;
  color: #4a4a4a;
}
.single-payment-method:not(:last-child) {
  margin-bottom: 20px;
}
.radio-label.selected,
.radio-label:hover {
  background-color: #53c392;
  color: white;
  border-color: #53c392;
}
.radio-label.selected .icon,
.radio-label:hover .icon {
  color: white;
}
.radio-label input {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
  top: 0px;
  margin: 0px;
  left: 0px;
}

